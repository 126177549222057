import { AuthConfig, OAuthEvent, OAuthService } from "angular-oauth2-oidc";
import { ConfigService } from "../services/config/config.service";
import { Subject, from } from "rxjs";
import { shareReplay, switchMap, take, tap } from "rxjs/operators";

export function initAuth(
    config: ConfigService,
    oauthService: OAuthService
) {
    console.log("initAuth started");
    function setBaseUrl(path: string): string {
        return path.startsWith("http")
            ? path
            : window.location.origin + path;
    }
    function addBaseURLToRedirects(authConfig: AuthConfig): AuthConfig {
        const issuer = authConfig["issuer"] == null ?
            window.location.origin : authConfig["issuer"];
        let postLogoutRedirectUri = authConfig.redirectUri;

        let returnAuthConfig = {
            ...authConfig,
            issuer: issuer,
            redirectUri: setBaseUrl(authConfig.redirectUri),
            postLogoutRedirectUri: setBaseUrl(postLogoutRedirectUri),
            //postLogoutRedirectUri: setBaseUrl(authConfig.redirectUri),
            silentRefreshRedirectUri: setBaseUrl(
                "/app/silent-refresh.html"
            )
        }
        console.log("authConfig:"+JSON.stringify(returnAuthConfig));

        return returnAuthConfig;
    }

    const reportError = (err) => {
        if(!err.params) return;
        const el = document.querySelector('.app-init-container .status');

        el.classList.add('error');
        el.innerHTML = `
         <div style="max-width: 300px">
            <h1>Error</h1>
            <p>Something went wrong, please try again.</p>
            <button class="btn btn-primary" onclick="location.href = location.href.split('?')[0]">Reload</button>
            </div>`;
    };

    return () => {
        const obs = new Subject<void>();

        oauthService.events.subscribe((event: OAuthEvent) => {
            console.log(
                `Auth Event: ${new Date().getTime()} ${
                    event.type
                } ${JSON.stringify(event).slice(0, 100)}...`
            );
        });
        let _configService: ConfigService;
        // Once config service info is loaded initiate authentication
        config.ready$
            .pipe(
                tap((c) => console.log("CONFIG LOADED", c)),
                switchMap((configService: ConfigService) => {
                    _configService = configService;
                    oauthService.configure(
                        addBaseURLToRedirects(configService.auth_config)
                    );
                    oauthService.strictDiscoveryDocumentValidation = false;

                    oauthService.setupAutomaticSilentRefresh();
                    // NW 29th April 2024 - Force different URL for infra test
                    // NW 3rd Feb 2025 - use this for all environments now
                    oauthService.loadDiscoveryDocument(
                        `${configService.auth_config.issuer}.well-known/openid-configuration?p=B2C_1A_SIGNUP_SIGNIN`);
                   
                    //if(window.location.href.toLowerCase().indexOf('localhost')>0 ||
                    //    window.location.href.toLowerCase().indexOf('infra-test')>0 ||
                    //    window.location.href.toLowerCase().indexOf('infratest')>0 ||
                    //    window.location.href.toLocaleLowerCase().indexOf('multi')>0 ||
                    //    window.location.href.toLocaleLowerCase().indexOf('testcloudevidencelocker')>0 ||
                    //    window.location.href.toLocaleLowerCase().indexOf('localhost')>0)
                    //{
                    //    oauthService.loadDiscoveryDocument(
                    //        `${configService.auth_config.issuer}.well-known/openid-configuration?p=B2C_1A_SIGNUP_SIGNIN`
                    //    );
                    //}
                    //else
                    //{
                    //    oauthService.loadDiscoveryDocument(
                    //        // NW 23rd April 2024 - experiment for Chris Pressley
                    //        //`${configService.auth_config.issuer}.well-known/openid-configuration?p=B2C_1A_SIGNUP_SIGNIN`
                    //            `${configService.auth_config.issuer}.well-known/openid-configuration?p=B2C_1_signin`
                    //    );
                    //}
                    return oauthService.discoveryDocumentLoaded$;
                }),
                take(1),
                switchMap(() =>
                    from(
                        oauthService.tryLogin()
                    ),
                ),
                shareReplay(),
                // switchMap((res) => {
                //       const testObs = new Subject();
                //       testObs.error({params: {error_description: 'test error'}});
                //       return testObs;
                //   })
            )
            .subscribe({
                next: (res) => {
                    // replace leading ./ with domain origin
                    const apiUrl = !_configService.API_URI.startsWith('http') ? location.origin + _configService.API_URI.replace(/^\./, '') : _configService.API_URI;
                    oauthService.logoutUrl = setBaseUrl(_configService.auth_config.redirectUri);
                    if (oauthService.hasValidIdToken()) {
                        obs.complete();
                    } else {
                        oauthService.initCodeFlow();
                    }
                },
                error: reportError,
            });



        return obs; // init app in observable completing
    };
}